/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import "normalize.css"

import Header from "./header"

const Main = styled.main`
  margin: 140px 25px 80px 25px;
  max-width: 550px;

  @media only screen and (min-width: 640px) {
    margin-top: 180px;
    margin-left: 20%;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 700px;
    margin-top: 200px;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 240px;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query Layout {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Header />
        <Main role="document">
          {children}
        </Main>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pagePath: PropTypes.node.isRequired,
}

export default Layout
