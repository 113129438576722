import React from "react"
import styled from "styled-components"
import "normalize.css"

const Heading = styled.h1`
  font-weight: 400;
  margin: 0 0 0.4em;
  letter-spacing: -0.02em;

  font-size: 3rem;
  line-height: 4.2rem;

  @media only screen and (min-width: 640px) {
    font-size: 3.2rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 4.2rem;
    line-height: 5.2rem;
  }
`


const Hero = () => {
  return (
    <>
      <Heading>Hi! I’m Oscar Léman, a Swedish guy who loves to create digital stuff.</Heading>
    </>
  )
}

export default Hero
