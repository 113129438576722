import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "normalize.css"

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  width: 100%;
  padding: 25px;

  @media only screen and (min-width: 640px) {
    background: transparent;
    padding: 0;
    top: 40px;
    left: 7%;
    width: auto;
  }

  @media only screen and (min-width: 1024px) {
    top: 65px;
  }

  @media only screen and (min-width: 1200px) {
    top: 80px;
  }
`

const Logo = styled(Link)`
  display: inline-block;
  line-height: 0;
  transform: rotate(0deg);
  transition: all 0.2s;
  border-bottom: 0;

  :hover {
    transform: rotate(-45deg) scale(1.1);
  }
`

const Svg = styled.svg`
  width: 50px;
  height: 50px;
  fill: #fff;
`

const BackPath = styled.path`
  display: none;
`

class Header extends React.Component {
  render() {
    return (
    <HeaderWrapper>
      <Logo to="/">
        <Svg xmlns="http://www.w3.org/2000/svg" width="126" height="126" viewBox="0 0 126 126">
          <g fill="#FFFFFF" fill-rule="evenodd">
            <path fill-rule="nonzero" d="M63.0563492,36.8994949 L36.8994949,63.0563492 L63.0563492,89.2132034 L63.0563492,66.5105777 L63.0563492,36.8994949 Z M63.0563492,27 L63.0563492,63.0563492 L63.0563492,99.1126984 L27,63.0563492 L63.0563492,27 Z" />
            <path fill-rule="nonzero" d="M10.6740982,63 L63,115.325902 L115.325902,63 L63,10.6740982 L10.6740982,63 Z M63,0.774603256 L125.225397,63 L63,125.225397 L0.774603256,63 L63,0.774603256 Z" />
            <BackPath d="M87.28,78 L87.28,49.4 L97.52,49.4 C103.4,49.4 106.88,51.64 106.88,56.84 L106.88,57 C106.88,59.84 105.6,62.08 102.36,63.08 C106.24,63.92 108.08,66.04 108.08,69.84 L108.08,70 C108.08,75.32 104.56,78 98.28,78 L87.28,78 Z M92.48,74.2 L97.64,74.2 C101.2,74.2 102.84,72.6 102.84,69.64 L102.84,69.48 C102.84,66.52 101.2,65.04 97.28,65.04 L92.48,65.04 L92.48,74.2 Z M92.48,61.44 L96.88,61.44 C100.4,61.44 101.76,60.16 101.76,57.24 L101.76,57.08 C101.76,54.36 100.2,53.2 96.8,53.2 L92.48,53.2 L92.48,61.44 Z M118.96,78.4 C115.12,78.4 111.96,76.56 111.96,72.2 C111.96,67.32 116.68,65.56 122.68,65.56 L125.28,65.56 L125.28,64.48 C125.28,61.68 124.32,60.28 121.48,60.28 C118.96,60.28 117.72,61.48 117.48,63.56 L112.8,63.56 C113.2,58.68 117.08,56.64 121.76,56.64 C126.44,56.64 130.12,58.56 130.12,64.28 L130.12,78 L125.36,78 L125.36,75.44 C124,77.24 122.16,78.4 118.96,78.4 Z M120.12,74.92 C123.08,74.92 125.28,73.36 125.28,70.68 L125.28,68.6 L122.8,68.6 C119.12,68.6 116.76,69.44 116.76,72 C116.76,73.76 117.72,74.92 120.12,74.92 Z M145.64,78.4 C151.32,78.4 154.96,75 155.32,70.2 L150.88,70.2 C150.6,73.16 148.4,74.68 145.84,74.68 C142.28,74.68 140.04,72.28 140.04,67.72 L140.04,67.4 C140.04,63.08 142.4,60.52 145.72,60.52 C148.28,60.52 150.12,61.68 150.52,64.48 L155.2,64.48 C154.64,58.72 150.28,56.64 145.64,56.64 C139.8,56.64 135.08,60.84 135.08,67.48 L135.08,67.8 C135.08,74.48 139.64,78.4 145.64,78.4 Z M160.28,78 L165.12,78 L165.12,67.72 L172.96,78 L178.56,78 L169.76,66.68 L177.88,57.08 L172.6,57.08 L165.12,66.28 L165.12,47.4 L160.28,47.4 L160.28,78 Z" />
          </g>
        </Svg>
      </Logo>
    </HeaderWrapper>
    )
  }
}

export default () => (
  <Header />
)
