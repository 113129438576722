import React from "react"
import styled from "styled-components"
import "normalize.css"

const Section = styled.div`
  margin-top: 15rem;
  max-width: 380px;

  @media only screen and (min-width: 640px) {
    max-width: 480px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 550px;
    font-size: 18px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 600px;
    font-size: 20px;
  }
`

const Heading = styled.h2`
  font-weight: 400;
  margin: 0 0 0.4em;
  letter-spacing: -0.02em;

  color: grey;
  margin-bottom: 1.5rem;
  font-size: 16px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 2.5rem;
    font-size: 18px;
  }
`

const About = () => {
  return (
    <Section>
      <Heading>About —</Heading>
      I’m a Stockholm-based product analyst & engineer with a passion for web and tech, currently working at <a href="https://scilla.studio">scilla.studio</a>.
    </Section>
  )
}


export default About
