import React from "react"
import { createGlobalStyle } from "styled-components"
import "normalize.css"

import GraphikRegularWebWoff from '../fonts/Graphik-Regular-Web.woff'
import GraphikRegularWebWoff2 from '../fonts/Graphik-Regular-Web.woff2'

import Layout from "../components/layout"
import Hero from "../components/hero"
import About from "../components/about"
import Lists from "../components/lists"
import SEO from "../components/seo"

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Graphik Web";
  src:
      url("${GraphikRegularWebWoff2}") format("woff2"),
      url("${GraphikRegularWebWoff}") format("woff");
  font-weight: 400;
  font-display: swap;
}

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: "Graphik Web", "Helvetica", sans-serif;
    -webkit-font-feature-settings: "liga", "kern";
    font-feature-settings: "liga", "kern";
  }

  ::selection {
    background: #fefff6;
    color: #000;
  }

  body {
    font-size: 1.6rem;
    line-height: 2.6rem;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: #000;
    color: #fefff6;

    @media only screen and (min-width: 1024px) {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  a {
    color: #fefff6;
    border-bottom: 1px #fefff6 dotted;
    padding-bottom: 0.2rem;
    transition: all 0.2s;
    text-decoration: none;

    &:focus,
    &:hover {
      border-bottom-color: grey;
    }
  }
`

const IndexPage = ({ location }) => {
  return (
    <>
      <React.Fragment>
        <GlobalStyle />
      </React.Fragment>
      <Layout pagePath={location.pathname}>
        <SEO
          title="Oscar Léman"
          pagePath={location.pathname}
        />
        <Hero />
        <About />
        <Lists />
      </Layout>
    </>
  )
}

export default IndexPage
